/**
 * Created by Vladyslav Dubyna 29/02/24
 *
 * this is Wallbox page
 */

import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { IProduct } from 'bsh-calculator';

import ContentArea from '../components/theme/ContentArea';
import Button from '../components/buttons/Button';
import Bottom from '../components/theme/Bottom';
import i18n from '../i18n';
import wallboxLogo from '../assets/images/wallbox.jpg';
import Select from '../components/forms/Select';
import CheckboxComponent, { CheckboxOption } from '../components/forms/Checkbox';
import { PlusMinusCounter } from '../components/plus-minus-counter/PlusMinusCounter';
import useStore from '../hooks/useStore';
import { SelectOption } from '../interfaces/IComponents';
import { useBSHNavigation } from '../hooks/useBSHNavigation';
import pages from '../const/pages';
import Validation from '../lib/Validation';
import { InputRefType } from '../components/forms/InputText';
import Tooltip from '../components/elements/Tooltip';
import Utils from '../lib/Utils';

type Option = { id: number; label: string; name: string };
// utility to find if the array has product
const arrayHasProduct = (array: any[], product: Nullable<IProduct> | undefined | Option) => array.some((item) => item.id === product?.id);

const WallboxPage: React.FC = observer(() => {
  const { dataStore, cacheStore, photovoltaikStore } = useStore();
  const wallboxRef = useRef<InputRefType>(null);
  const {
    wallboxQuantity,
    wallboxSelectionId,
    selectionJuiceBooster2,
    juiceBooster2Quantity,
    selectionSteleSenecPremium,
    steleSenecPremiumQuantity,
    steleSenecPlusQuantity,
    selectionSteleSenecPlus,
  } = dataStore.wallboxForm;

  const { goToNextPage, goToPreviousPage, isCurrentFormLast } = useBSHNavigation();
  const [nextButtonTooltip, setNextButtonTooltip] = useState(false);

  // method to handle plus-minus change
  const handleQuantityChangeByName = (name: keyof typeof dataStore.wallboxForm) => (number: number) => {
    dataStore.setWallboxForm({ ...dataStore.wallboxForm, [name]: number });
  };

  const { formSwitchers } = dataStore;
  const { storageForm, internalCalculatedStorageValue } = photovoltaikStore;

  const wallboxProducts = cacheStore.getProductsByGroupId('wallbox');

  useEffect(() => {
    const refValue = wallboxRef.current;

    if (refValue) {
      const validObj = refValue.inputValidate(wallboxSelectionId ?? '');

      if (!validObj.validated) {
        refValue.setValidation(validObj);
      }
    }
  }, [wallboxSelectionId]);

  /**
   * object to abstract dependencies to avoid using ifs
   * it has dependencyArray and dependend values that will be changed if value that we use will be in that dependency array
   */

  // TODO: find a way not to depend on name values
  const selectionSteleSenecPlusValues = (wallboxProducts || []).filter((product) => product.name.includes('plus'));
  const selectionStelePremuimPlusValues = (wallboxProducts || []).filter((product) => product.name.includes('premium'));

  const wallboxSelectionDependencies = [
    {
      dependencyArray: selectionStelePremuimPlusValues,
      depenedendSelectionValue: 'selectionSteleSenecPlus',
      dependendQuantityValue: 'steleSenecPlusQuantity',
    },
    {
      dependencyArray: selectionSteleSenecPlusValues,
      depenedendSelectionValue: 'selectionSteleSenecPremium',
      dependendQuantityValue: 'selectionSteleSenecPremium',
    },
  ];

  // function which will return new form values or an empty object
  const addFormValuesByDependencies = (value: SelectOption) => {
    const dependencyObject = wallboxSelectionDependencies.find((dependency) =>
      dependency.dependencyArray.some((element) => element.id === value.id));

    if (dependencyObject) {
      return {
        [dependencyObject.depenedendSelectionValue]: false,
        [dependencyObject.dependendQuantityValue]: undefined,
      };
    }

    return {};
  };

  // get wallbox page options
  const getWallboxOptions = () => {
    const isPhotovoltaic = formSwitchers.find((form) => form.url === pages.photovoltaikQuote && form.checked);
    // filter juice booster out of the list
    const filteredWallboxProducts = wallboxProducts?.filter((prod) => prod.serviceId !== 'juice_booster_2_die_mobile_22_kw_wallbox') ?? [];

    if (isPhotovoltaic) {
      const storage = cacheStore.getProductById(storageForm.storagesId ?? '', '', true);

      if (storage) {
        const sameBrand = filteredWallboxProducts.filter((box) => box.brandId === storage?.brandId);

        return (
          sameBrand?.map((prod) => ({
            id: prod.serviceId,
            label: prod.name,
          })) ?? []
        );
      }

      if (internalCalculatedStorageValue) {
        const calculatedStorage = cacheStore.getProductById(internalCalculatedStorageValue);
        const sameBrand = filteredWallboxProducts.filter((box) => box.brandId === calculatedStorage?.brandId);

        const showed = sameBrand?.length ? sameBrand : wallboxProducts;

        return (
          showed?.map((prod) => ({
            id: prod.serviceId,
            label: prod.name,
          })) ?? []
        );
      }
    }

    return (
      filteredWallboxProducts.map((prod) => ({
        id: prod.serviceId,
        label: prod.name,
      })) ?? []
    );
  };

  // method to handle checkbox change
  const handleCheckboxChangeByName =
    (name: keyof typeof dataStore.wallboxForm, dependentValue: keyof typeof dataStore.wallboxForm) => (newOptions: CheckboxOption[]) => {
      const valueByName = newOptions.find((newOption) => newOption.id === name);

      // const additionalCheckboxes = ['selectionJuiceBooster2', 'selectionSteleSenecPlus', 'selectionSteleSenecPremium'];

      // if (additionalCheckboxes.includes(name))

      dataStore.setWallboxForm({
        ...dataStore.wallboxForm,
        [name]: valueByName?.checked,
        [dependentValue]: valueByName?.checked ? 1 : undefined,
      });
    };

  const wallboxSelection = cacheStore.getProductById(wallboxSelectionId ?? '');

  const shouldShowSelectionSteleSenecPlusCheckbox = arrayHasProduct(selectionSteleSenecPlusValues, wallboxSelection);
  const shouldShowSelectionSteleSenecPremiumCheckbox = arrayHasProduct(selectionStelePremuimPlusValues, wallboxSelection);

  // method to handle select change
  const handleSelectChangeByName = (name: keyof typeof dataStore.wallboxForm) => (value: SelectOption) => {
    const formToSet = { ...dataStore.wallboxForm, [name]: value.id.toString() };

    const valuesTOUpdateByDependency = addFormValuesByDependencies(value);

    const isSpecials = shouldShowSelectionSteleSenecPlusCheckbox || shouldShowSelectionSteleSenecPremiumCheckbox;

    if (name === 'wallboxSelectionId') {
      if (isSpecials) {
        formToSet.selectionSteleSenecPlus = false;
        formToSet.steleSenecPlusQuantity = undefined;
        formToSet.selectionSteleSenecPremium = false;
        formToSet.steleSenecPremiumQuantity = undefined;
      }

      formToSet.wallboxQuantity = 1;
    }

    dataStore.setWallboxForm({ ...formToSet, ...valuesTOUpdateByDependency });
  };

  const emptyValidateFunction = (p: string) => Validation.emptyValue(p, i18n.t('validations.fieldRequired'));

  const selectOptions = [{ id: '', label: i18n.t('photovoltaikPage.noSelection') }, ...getWallboxOptions().sort(Utils.customSort)];

  return (
    <div className="content-inner">
      <ContentArea>
        <div className="content-main">
          <div className="wallbox-title-row">
            <img src={wallboxLogo} className="title-image" alt="photovoltaik" />
            <h2 className="wallbox-title">{i18n.t('forms.wallbox')}</h2>
          </div>
          <div className="wallbox-row">
            <Select
              ref={wallboxRef}
              title={i18n.t('wallboxPage.wallboxSelection')}
              value={
                wallboxSelection
                  ? {
                    id: wallboxSelection?.serviceId,
                    label: wallboxSelection?.name,
                  }
                  : undefined
              }
              options={selectOptions}
              testId="wallboxSelection"
              placeholder={i18n.t('photovoltaikPage.noSelection')}
              validateFunc={emptyValidateFunction}
              onSuggestionSelected={handleSelectChangeByName('wallboxSelectionId')}
            />
            <PlusMinusCounter
              keepToRight
              value={wallboxQuantity}
              testId="wallboxSelectionQuantity"
              title={i18n.t('wallboxPage.wallboxQuantity')}
              onChange={handleQuantityChangeByName('wallboxQuantity')}
              min={1}
              className="counter"
            />
          </div>
          <div className="wallbox-row">
            <CheckboxComponent
              options={[
                {
                  id: 'selectionJuiceBooster2',
                  checked: selectionJuiceBooster2 || false,
                  value: i18n.t('wallboxPage.juiceBooster'),
                  testId: 'selectionJuiceBooster2',
                },
              ]}
              onChange={handleCheckboxChangeByName('selectionJuiceBooster2', 'juiceBooster2Quantity')}
              className="memory-checkboxes"
              isSingle
            />
            {selectionJuiceBooster2 && (
              <PlusMinusCounter
                onChange={handleQuantityChangeByName('juiceBooster2Quantity')}
                title={i18n.t('wallboxPage.juiceBoosterQuantity')}
                keepToRight
                value={juiceBooster2Quantity}
                min={1}
                testId="juiceBooster2Quantity"
                className="counter"
              />
            )}
          </div>
          {shouldShowSelectionSteleSenecPlusCheckbox && (
            <div className="wallbox-row">
              <CheckboxComponent
                options={[
                  {
                    id: 'selectionSteleSenecPlus',
                    checked: selectionSteleSenecPlus || false,
                    value: i18n.t('wallboxPage.selectionSteleSenecPlus'),
                    testId: 'selectionSteleSenecPlus',
                  },
                ]}
                className="memory-checkboxes"
                isSingle
                onChange={handleCheckboxChangeByName('selectionSteleSenecPlus', 'steleSenecPlusQuantity')}
              />
              {selectionSteleSenecPlus && (
                <PlusMinusCounter
                  value={steleSenecPlusQuantity}
                  onChange={handleQuantityChangeByName('steleSenecPlusQuantity')}
                  title={i18n.t('wallboxPage.steleSenecPlusQuantity')}
                  testId="steleSenecPlusQuantity"
                  keepToRight
                  className="counter"
                  min={1}
                />
              )}
            </div>
          )}
          {shouldShowSelectionSteleSenecPremiumCheckbox && (
            <div className="wallbox-row">
              <CheckboxComponent
                options={[
                  {
                    id: 'selectionSteleSenecPremium',
                    checked: selectionSteleSenecPremium || false,
                    value: i18n.t('wallboxPage.selectionSteleSenecPremium'),
                    testId: 'selectionSteleSenecPremium',
                  },
                ]}
                onChange={handleCheckboxChangeByName('selectionSteleSenecPremium', 'steleSenecPremiumQuantity')}
                className="memory-checkboxes"
                isSingle
              />
              {selectionSteleSenecPremium && (
                <PlusMinusCounter
                  value={steleSenecPremiumQuantity}
                  onChange={handleQuantityChangeByName('steleSenecPremiumQuantity')}
                  title={i18n.t('wallboxPage.steleSenecPremiumQuantity')}
                  testId="steleSenecPremiumQuantity"
                  keepToRight
                  min={1}
                  className="counter"
                />
              )}
            </div>
          )}
        </div>
      </ContentArea>
      <Bottom>
        <Button role="btnCancel" text={i18n.t('buttons.back')} onClick={goToPreviousPage} />
        <Tooltip
          isShow={nextButtonTooltip && !wallboxSelectionId}
          text={i18n.t('validations.validateNextButton')}
          position="top"
          bottomButtons
        >
          <Button
            role="btnNext"
            text={i18n.t('buttons.next')}
            testId="next-button"
            isDisabled={!wallboxSelectionId || isCurrentFormLast(pages.wallboxQoute)}
            onClick={() => {
              dataStore.syncWallBoxParseData();
              goToNextPage();
            }}
            isGreen
            leftMargin20
            onMouseEnter={() => setNextButtonTooltip(true)}
            onMouseLeave={() => setNextButtonTooltip(false)}
          />
        </Tooltip>
      </Bottom>
    </div>
  );
});

export default WallboxPage;
