/**
 * Created by Max Gornostayev 01/14/24
 *
 * pageId: missingRequiredData
 * path: /missingRequiredData
 *
 * this is basic data page for the customer
 */
import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import Button from '../components/buttons/Button';
import InputText, { InputRefType } from '../components/forms/InputText';
import Validation from '../lib/Validation';
import pageUrl from '../const/pages';
import { ICustomer } from '../interfaces/IModels';
import i18n from '../i18n';
import '../styles/secure.scss';
import useStore from '../hooks/useStore';
import AddressInput from '../components/forms/AddressInput';
import { GooglePlaceDetails } from '../services/GoogleMapsAutocomplete';
import Tooltip from '../components/elements/Tooltip';

const MissingRequiredDataPage = observer(() => {
  // get user store
  const { userStore, dataStore } = useStore();
  const { customer } = userStore;
  const { internalObjectAddress } = dataStore;

  // create refs
  const firstNameRef = useRef<InputRefType>(null);
  const lastNameRef = useRef<InputRefType>(null);
  const postcodeRef = useRef<InputRefType | null>(null);
  const cityRef = useRef<InputRefType | null>(null);
  const emailRef = useRef<InputRefType | null>(null);

  const [streetValidationMsg, setStreetValidationMsg] = useState('');

  // state variables
  const [nextButtonTooltip, setNextButtonTooltip] = useState(false);

  // navigate variable that is used to go to another screen
  const navigate = useNavigate();

  // set customer data in store
  const setUserData = (newCustomer: ICustomer) => {
    userStore.updateCustomer(newCustomer);
  };

  const highlightRequiredFields = (checking = false) => {
    let formValidated = true;

    const refs: [InputRefType | null, string?][] = [
      [firstNameRef.current, customer.firstName?.toString()],
      [lastNameRef.current, customer.lastName?.toString()],
      [cityRef.current, internalObjectAddress?.city?.toString()],
      [postcodeRef.current, internalObjectAddress?.postcode?.toString()],
      [emailRef.current, customer.email],
    ];

    refs.map(([refValue, text]) => {
      if (refValue) {
        const validObj = refValue.inputValidate(text ?? '');

        if (!validObj.validated) {
          refValue.setValidation(validObj);
          formValidated = false;
        }
      }

      return refValue;
    });

    const streetValidation = Validation.addressWithoutNumber(internalObjectAddress?.street ?? '');

    if (!streetValidation.validated) {
      !checking && setStreetValidationMsg(streetValidation.msg);
      formValidated = false;
    }

    return formValidated;
  };

  useEffect(() => {
    highlightRequiredFields();
  }, [customer, internalObjectAddress]);

  // button handler
  const next = async () => {
    const formValidated = highlightRequiredFields();

    if (formValidated) {
      dataStore.syncMissingDataParse();
      navigate(pageUrl.basicData);
    }
  };

  // handle street selection from google autocomplete
  const handleStreetSelection = (details: GooglePlaceDetails) => {
    const refs: [InputRefType | null, string][] = [
      [postcodeRef.current, details.postalCode],
      [cityRef.current, details.city],
      [postcodeRef.current, details.postalCode],
    ];

    refs.map(([refValue, text]) => {
      if (refValue) {
        const validObj = refValue.inputValidate(text);

        refValue.setValidation(validObj);
      }

      return refValue;
    });

    dataStore.setInternalObjectAddress({
      postcode: details.postalCode,
      city: details.city,
      street: `${details.streetName} ${details.streetNumber}`,
    });

    const streetValidation = Validation.addressWithoutNumber(details.streetName);

    setStreetValidationMsg(streetValidation.validated ? '' : streetValidation.msg);
  };

  // handle back click
  const handleBackClick = () => {
    window.open('about:blank', '_self');
    window.close();
  };

  const emptyValidateFunction = (p: string) => Validation.emptyValue(p, i18n.t('validations.fieldRequired'));

  const isFormValidated = highlightRequiredFields(true);

  // main rendering
  return (
    <div className="app missing-data-page">
      <div className="missing-wrapper">
        <div className="missing-content">
          <h2>{i18n.t('missingRequiredDataPage.h2')}</h2>
          <p>{i18n.t('missingRequiredDataPage.desc')}</p>
          <h2 className="title">{i18n.t('basicDataPage.contactDetails').toUpperCase()}</h2>
          <div className="container">
            <div className="row">
              <InputText
                ref={firstNameRef}
                role="firstNameInput"
                title={i18n.t('missingRequiredDataPage.firstName')}
                value={customer.firstName}
                onChange={(val) => setUserData({ ...customer, firstName: val as string })}
                testId="first-name-input"
                className="missing-input"
                validateFunc={emptyValidateFunction}
              />
              <InputText
                ref={lastNameRef}
                role="lastNameInput"
                title={i18n.t('missingRequiredDataPage.lastName')}
                value={customer.lastName}
                onChange={(val) => setUserData({ ...customer, lastName: val as string })}
                testId="last-name-input"
                className="missing-input"
                validateFunc={emptyValidateFunction}
              />
            </div>
            <div className="row">
              <InputText
                ref={emailRef}
                role="emailInput"
                title={i18n.t('missingRequiredDataPage.email')}
                value={customer.email}
                onChange={(val) => setUserData({ ...customer, email: val as string })}
                validateFunc={(val) => Validation.email(val)}
                testId="email-input"
              />
            </div>
            <h2 className="title">{i18n.t('basicDataPage.propertyAddress').toUpperCase()}</h2>
            <div className="row">
              <AddressInput
                role="houseNumber"
                title={i18n.t('missingRequiredDataPage.streetName')}
                value={internalObjectAddress?.street ?? ''}
                onChange={(val) => {
                  const validObj = Validation.addressWithoutNumber(val?.toString() ?? '');

                  dataStore.setInternalObjectAddress({ ...internalObjectAddress, street: val as string });
                  setStreetValidationMsg(validObj.msg);
                }}
                className="autocomplete-input"
                onStreetSelected={handleStreetSelection}
                testId="street-name-input"
                validateMsg={streetValidationMsg}
                validateFunc={(val) => Validation.addressWithoutNumber(val)}
              />
            </div>
            <div className="row">
              <InputText
                ref={postcodeRef}
                role="postcodeInput"
                title={i18n.t('missingRequiredDataPage.postcode')}
                value={internalObjectAddress?.postcode ?? ''}
                onChange={(val) => dataStore.setInternalObjectAddress({ ...internalObjectAddress, postcode: val as string })}
                validateFunc={(val) => Validation.postcode(val)}
                testId="postcode-input"
                className="missing-input"
              />
              <InputText
                ref={cityRef}
                role="cityInput"
                title={i18n.t('missingRequiredDataPage.city')}
                value={internalObjectAddress?.city ?? ''}
                onChange={(val) => dataStore.setInternalObjectAddress({ ...internalObjectAddress, city: val as string })}
                testId="city-input"
                className="missing-input"
                validateFunc={emptyValidateFunction}
              />
            </div>
          </div>
        </div>
        <div className="missing-buttons">
          <Button role="btnCancel" text={i18n.t('buttons.cancel').toUpperCase()} onClick={handleBackClick} />
          <Tooltip
            isShow={nextButtonTooltip && !isFormValidated}
            text={i18n.t('validations.validateNextButton')}
            position="top"
            bottomButtons
          >
            <Button
              role="btnNext"
              text={i18n.t('buttons.next')}
              onClick={next}
              isGreen
              leftMargin20
              testId="next-button"
              isDisabled={!isFormValidated}
              onMouseEnter={() => setNextButtonTooltip(true)}
              onMouseLeave={() => setNextButtonTooltip(false)}
            />
          </Tooltip>
        </div>
      </div>
      <div className="responsive-error">
        <span className="responsive-error-title">{i18n.t('header.title')}</span>
        <span className="responsive-error-subtitle">{i18n.t('errors.responsiveUIError')}</span>
      </div>
    </div>
  );
});

export default MissingRequiredDataPage;
