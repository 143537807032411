/**
 * Created by Ilya Savinov 29/01/24
 *
 * this is memory extension page
 */
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import ContentArea from '../components/theme/ContentArea';
import i18n from '../i18n';
import memoryExtension from '../assets/images/memoryExtension.png';
import Select from '../components/forms/Select';
import Bottom from '../components/theme/Bottom';
import Button from '../components/buttons/Button';
import useStore from '../hooks/useStore';
import { SelectOption } from '../interfaces/IComponents';
import { InputRefType } from '../components/forms/InputText';
import Validation from '../lib/Validation';
import { useBSHNavigation } from '../hooks/useBSHNavigation';
import { PlusMinusCounter } from '../components/plus-minus-counter/PlusMinusCounter';
import pages from '../const/pages';
import { MemoryExtensionForm } from '../types/TGeneral';
import Tooltip from '../components/elements/Tooltip';
import Utils from '../lib/Utils';

const MemoryExtensionPage = observer(() => {
  const { goToNextPage, goToPreviousPage, isCurrentFormLast } = useBSHNavigation();
  const { cacheStore, dataStore, photovoltaikStore, memoryExtensionStore } = useStore();
  const { formSwitchers } = dataStore;
  const { memoryExtensionForm } = memoryExtensionStore;
  const { storageForm, internalCalculatedStorageValue } = photovoltaikStore;
  const { memoryExtensionSelectionId, numberExistingBatteries, numberAdditionalBattery } = memoryExtensionForm;

  const [nextButtonTooltip, setNextButtonTooltip] = useState(false);

  const selectRef = useRef<InputRefType>(null);

  useEffect(() => {
    const refValue = selectRef.current;

    if (refValue) {
      const validObj = refValue.inputValidate(memoryExtensionSelectionId ?? '');

      if (!validObj.validated) {
        refValue.setValidation(validObj);
      }
    }
  }, [memoryExtensionSelectionId]);

  // get memory extension select options
  const getMemoryExtensionOptions = () => {
    const memoryExtensionProducts = cacheStore.getProductsByGroupId('memoryExtension');

    const isPhotovoltaic = formSwitchers.find((form) => form.url === pages.photovoltaikQuote && form.checked);

    if (isPhotovoltaic) {
      const storage = cacheStore.getProductById(storageForm.storagesId ?? '', '', true);

      if (storage) {
        const sameBrand = memoryExtensionProducts?.filter((box) => box.brandId === storage?.brandId);

        const showed = sameBrand?.length ? sameBrand : memoryExtensionProducts;

        return (
          showed?.map((prod) => ({
            id: prod.serviceId,
            label: prod.name,
          })) ?? []
        );
      }

      if (internalCalculatedStorageValue) {
        const calculatedStorage = cacheStore.getProductById(internalCalculatedStorageValue);
        const sameBrand = memoryExtensionProducts?.filter((box) => box.brandId === calculatedStorage?.brandId);

        return (
          sameBrand?.map((prod) => ({
            id: prod.serviceId,
            label: prod.name,
          })) ?? []
        );
      }
    }

    return (
      memoryExtensionProducts?.map((prod) => ({
        id: prod.serviceId,
        label: prod.name,
      })) ?? []
    );
  };

  const memoryExtensionSelection = cacheStore.getProductById(memoryExtensionSelectionId ?? '');

  const selectValue = memoryExtensionSelection ? { label: memoryExtensionSelection.name, id: memoryExtensionSelection.id } : null;

  // handle select value change
  const handleSelectChange = (option: SelectOption) =>
    memoryExtensionStore.setMemoryExtensionForm({
      ...memoryExtensionForm,
      memoryExtensionSelectionId: option.id.toString(),
    });

  // handle next click button
  const handleNextClick = async () => {
    if (memoryExtensionSelectionId) {
      memoryExtensionStore.syncMemoryExtensionData();
      goToNextPage();
    }
  };

  // handle inputs change
  const handleQuantities = (name: keyof MemoryExtensionForm) => (newValue: number) => {
    memoryExtensionStore.setMemoryExtensionForm({
      ...memoryExtensionForm,
      [name]: newValue,
    });
  };

  const selectOptions = [...getMemoryExtensionOptions().sort(Utils.customSort)];

  return (
    <ContentArea>
      <div className="content-main">
        <div className="wallbox-title-row">
          <img src={memoryExtension} className="title-image" alt="photovoltaik" />
          <h2 className="wallbox-title">{i18n.t('forms.spExtension')}</h2>
        </div>
        <Select
          ref={selectRef}
          value={selectValue}
          title={i18n.t('memoryExtensionPage.selectMemoryExtension')}
          placeholder={i18n.t('photovoltaikPage.noSelection')}
          options={selectOptions}
          onSuggestionSelected={handleSelectChange}
          testId="memoryExtension-select"
          validateFunc={(p) => Validation.emptyValue(p, i18n.t('validations.fieldRequired'))}
        />
        <div className="memory-counters-row">
          <PlusMinusCounter
            title={i18n.t('memoryExtensionPage.numberOfExistingBatteries')}
            className="memory-counter"
            value={numberExistingBatteries}
            onChange={handleQuantities('numberExistingBatteries')}
            testId="existing-batteries"
            min={0}
          />
          <PlusMinusCounter
            title={i18n.t('memoryExtensionPage.numberOfAdditionalBatteries')}
            className="memory-counter"
            value={numberAdditionalBattery}
            onChange={handleQuantities('numberAdditionalBattery')}
            testId="additional-batteries"
            min={0}
          />
        </div>
      </div>
      <Bottom>
        <Button role="btnCancel" text={i18n.t('buttons.back')} onClick={goToPreviousPage} />
        <Tooltip
          isShow={nextButtonTooltip && !memoryExtensionSelectionId}
          text={i18n.t('validations.validateNextButton')}
          position="top"
          bottomButtons
        >
          <Button
            role="btnNext"
            text={i18n.t('buttons.next')}
            isGreen
            leftMargin20
            onClick={handleNextClick}
            testId="next-button"
            isDisabled={!memoryExtensionSelectionId || isCurrentFormLast(pages.memoryExtension)}
            onMouseEnter={() => setNextButtonTooltip(true)}
            onMouseLeave={() => setNextButtonTooltip(false)}
          />
        </Tooltip>
      </Bottom>
    </ContentArea>
  );
});

export default MemoryExtensionPage;
