/**
 * Created by Vladyslav Dubyna 29/02/24
 *
 * this is Storage extension page
 */

import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef } from 'react';

import ContentArea from '../components/theme/ContentArea';
import i18n from '../i18n';
import storagesExtension from '../assets/images/storageExtension.jpg';
import Select from '../components/forms/Select';
import Bottom from '../components/theme/Bottom';
import Button from '../components/buttons/Button';
import useStore from '../hooks/useStore';
import { SelectOption } from '../interfaces/IComponents';
import { InputRefType } from '../components/forms/InputText';
import Validation from '../lib/Validation';
import { useBSHNavigation } from '../hooks/useBSHNavigation';
import { StoragesExtensionForm } from '../types/TGeneral';
import pages from '../const/pages';

const mockData = [{ serviceId: 'enerFusion', name: 'Ener.Fusion' }].map((product) => ({
  id: product.serviceId,
  label: product.name,
}));

const StorageExtensionPage = observer(() => {
  const { goToNextPage, goToPreviousPage, isCurrentFormLast } = useBSHNavigation();
  const { dataStore, cacheStore, photovoltaikStore } = useStore();
  const { storageExtensionForm, formSwitchers } = dataStore;
  const { storageForm, internalCalculatedStorageValue } = photovoltaikStore;

  const { storageExtensionSelectionId } = storageExtensionForm;

  const storageExtensionProducts = cacheStore.getProductsByGroupId('storageExtension');
  const storage = cacheStore.getProductById(storageForm.storagesId ?? '', '', true);

  // get wallbox page options
  const storageExtensionOptions = useMemo(() => {
    const isPhotovoltaic = formSwitchers.find((form) => form.url === pages.photovoltaikQuote && form.checked);

    if (isPhotovoltaic) {
      if (storage) {
        const sameBrand = storageExtensionProducts?.filter((box) => box.brandId === storage?.brandId);

        return (
          sameBrand?.map((prod) => ({
            id: prod.serviceId,
            label: prod.name,
          })) ?? []
        );
      }

      if (internalCalculatedStorageValue) {
        const calculatedStorage = cacheStore.getProductById(internalCalculatedStorageValue);
        const sameBrand = storageExtensionProducts?.filter((box) => box.brandId === calculatedStorage?.brandId);

        const showed = sameBrand?.length ? sameBrand : storageExtensionProducts;

        return (
          showed?.map((prod) => ({
            id: prod.serviceId,
            label: prod.name,
          })) ?? []
        );
      }
    }

    return (
      storageExtensionProducts?.map((prod) => ({
        id: prod.serviceId,
        label: prod.name,
      })) ?? []
    );
  }, [formSwitchers, storage, internalCalculatedStorageValue]);

  const selectOptions = (storageExtensionProducts?.length ?? 0) > 0 ? storageExtensionOptions : mockData;

  const storageExtensionSelection = selectOptions.find((item) => item.id === storageExtensionSelectionId);

  const selectRef = useRef<InputRefType>(null);

  useEffect(() => {
    const refValue = selectRef.current;

    if (refValue) {
      const validObj = refValue.inputValidate(storageExtensionSelectionId ?? '');

      if (!validObj.validated) {
        refValue.setValidation(validObj);
      }
    }
  }, [storageExtensionSelectionId]);

  // seelect handle method
  const handleSelectChangeByName = (name: keyof typeof dataStore.storageExtensionForm) => (value: SelectOption) => {
    const formToSet = { ...dataStore.storageExtensionForm, [name]: value.id };

    dataStore.setStoragesExtensionForm(formToSet as unknown as StoragesExtensionForm);
  };

  // handle next button click
  const handleNextClick = () => {
    if (storageExtensionSelectionId) {
      dataStore.syncStorageExtensionParseData();
      goToNextPage();
    }
  };

  return (
    <ContentArea>
      <div className="content-main">
        <div className="wallbox-title-row">
          <img src={storagesExtension} className="title-image" alt="photovoltaik" />
          <h2 className="wallbox-title">{i18n.t('forms.spStorageExtension').toUpperCase()}</h2>
        </div>
        <div className="blackout-select-container">
          <Select
            ref={selectRef}
            value={
              storageExtensionSelection
                ? {
                  id: storageExtensionSelection?.id,
                  label: storageExtensionSelection?.label,
                }
                : undefined
            }
            title={`${i18n.t('common.quantity')} ${i18n.t('storageExtensionPage.storages')}`}
            placeholder={i18n.t('photovoltaikPage.noSelection')}
            options={selectOptions}
            onSuggestionSelected={handleSelectChangeByName('storageExtensionSelectionId')}
            testId="storageExtension-select"
            validateFunc={(p) => Validation.emptyValue(p, i18n.t('validations.fieldRequired'))}
          />
        </div>
      </div>
      <Bottom>
        <Button role="btnCancel" text={i18n.t('buttons.back')} onClick={goToPreviousPage} />
        <Button
          role="btnNext"
          text={i18n.t('buttons.next')}
          isGreen
          leftMargin20
          isDisabled={isCurrentFormLast(pages.storageExtension)}
          onClick={handleNextClick}
          testId="next-button"
        />
      </Bottom>
    </ContentArea>
  );
});

export default StorageExtensionPage;
