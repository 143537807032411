/**
 * Created by Vladyslav Dubyna 29/02/24
 *
 * this is Financing page
 */

import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { ProductResultType } from 'bsh-calculator';

import ContentArea from '../components/theme/ContentArea';
import Button from '../components/buttons/Button';
import Bottom from '../components/theme/Bottom';
import i18n from '../i18n';
import { useBSHNavigation } from '../hooks/useBSHNavigation';
import Select from '../components/forms/Select';
import InputText from '../components/forms/InputText';
import CheckboxWithInput from '../components/forms/CheckboxWithInput';
import CheckboxComponent, { CheckboxOption } from '../components/forms/Checkbox';
import Dropdown from '../components/dropdown/Dropdown';
import arrow from '../assets/icons/bigArrow.png';
import Slider from '../components/forms/Slider';
import Tooltip from '../components/elements/Tooltip';
import useStore from '../hooks/useStore';
import { SelectOption } from '../interfaces/IComponents';
import { useCalculator } from '../hooks/useCalculator';
import { toFloatNumber } from '../lib/PageUtils';
import pageUrls from '../const/pages';
import {
  FEATURES_NAMES_MAP_BY_URL,
  MAX_INTEREST_RATE_HOUSE_BANK,
  MIN_INTEREST_RATE_HOUSE_BANK,
  financingViaOptions,
  getFeaturesOptions,
  getFinancingViaOptions,
  getPaymentOptions,
  hirePurchaseTermValues,
  paymentSelectionOptions,
  updateByLeasingSelfEmployment,
  updateByRentingSelfEmployment,
} from './financingPageUtils';
import { calculateFinancingInterestRate } from '../helpers/finance';
import { getNetPrices } from '../helpers/widget';

const Financing: React.FC = observer(() => {
  const { goToPreviousPage } = useBSHNavigation();
  const { dataStore, userStore, financingStore } = useStore();

  const { calculatorResult, calculator } = useCalculator();

  const [showFinancingViaBshTooltip, setShowFinancingViaBshTooltip] = React.useState<boolean>(false);
  const [showLeasingTooltip, setShowLeasingTooltip] = React.useState<boolean>(false);
  const [showLeasingTooltipWp, setShowLeasingTooltipWp] = React.useState<boolean>(false);
  const { pvProducts = {} } = calculatorResult as ProductResultType;
  const { financingForm, featuresOptions } = financingStore;
  const {
    paymentSelection,
    financingVia,
    financingInterestRate,
    discountInEurosSolarSpecial,
    bshEstate,
    discountInEuros,
    discountInPercent,
    estateDescription,
    financingDownPayment,
  } = financingForm;

  const isWpSelected = featuresOptions.find((feature) => feature.url === pageUrls.hotWaterPump)?.checked;

  useEffect(() => {
    const options = getFeaturesOptions(dataStore);

    if (financingForm.leasingDownPayment || financingForm.leasingDownPaymentWp) {
      const netPrices = getNetPrices(calculatorResult);

      netPrices &&
        financingForm.leasingDownPayment &&
        financingStore.setFeaturesOnPrice(options, netPrices, financingForm.leasingDownPayment);
      netPrices &&
        financingForm.leasingDownPaymentWp &&
        financingStore.setFeaturesOnPrice(options, netPrices, financingForm.leasingDownPaymentWp);
    } else {
      financingStore.setFeatureOptions(options);
    }
  }, [dataStore.formSwitchers]);

  /**
   * handle features change
   * @param options - new options
   */
  const onFeaturesChange = (options: CheckboxOption[]) => {
    const value = options[0] as CheckboxOption & { url: string };

    if (value.url === pageUrls.photovoltaikQuote && !value.checked) {
      financingStore.setFinancingForm({
        ...financingForm,
        solarSpecial: false,
        discountInEurosSolarSpecial: 0,
        discountInEuros: 0,
        discountInPercent: 0,
        bshEstate: false,
      });
    }

    if (value.url === pageUrls.hotWaterPump && !value.checked) {
      financingStore.setFinancingForm({
        ...financingForm,
        leasingDownPaymentWp: 0,
      });
    }

    const updatedFeatures = featuresOptions.map((feature) => {
      if (feature.id === value.id) {
        return { ...feature, checked: value.checked };
      }

      return { ...feature };
    });

    if (financingVia === 'financingViaBsh') {
      const isWp = updatedFeatures.find((feature) => feature.url === pageUrls.hotWaterPump && feature.checked)?.checked;

      financingStore.setFinancingForm({ ...financingForm, financingInterestRate: calculateFinancingInterestRate(isWp) });
    }

    const isPvOrWpSelected = updatedFeatures.some(
      (feature) => (feature.url === pageUrls.hotWaterPump || feature.url === pageUrls.photovoltaikQuote) && feature.checked,
    );

    if (!isPvOrWpSelected) {
      const newFeatures = updatedFeatures.map((feature) => {
        if (feature.url !== pageUrls.hotWaterPump && feature.url !== pageUrls.photovoltaikQuote) {
          return { ...feature, checked: false, disabled: true };
        }

        return feature;
      });

      financingStore.setFeatureOptions(newFeatures);
    } else if (financingForm.leasingDownPayment) {
      const netPrices = getNetPrices(calculatorResult);

      netPrices && financingStore.setFeaturesOnPrice(updatedFeatures, netPrices, financingForm.leasingDownPayment);
    } else {
      const newFeatures = updatedFeatures.map((feature) => {
        if (feature.url !== pageUrls.hotWaterPump && feature.url !== pageUrls.photovoltaikQuote) {
          return { ...feature, disabled: false };
        }

        return feature;
      });

      financingStore.setFeatureOptions(newFeatures);
    }
  };

  const summmaryPriceNetWithSolarSpecial = Number(pvProducts.summaryPriceNet);

  const summaryPriceNetWitSolarSpecialWithoutDiscount =
    Number(summmaryPriceNetWithSolarSpecial) + Number(financingForm.discountInEuros || 0);

  /**
   * handle selections change
   * @param name - propery name
   * @param value - new value
   */
  const onSelectChangeByName = (name: keyof typeof financingForm) => (value: SelectOption) => {
    let formToUpdate = { ...financingForm };

    if (name === 'financingVia') {
      if (value.id === 'financingViaHouseBank') {
        let newInterestRate = Number(formToUpdate.financingInterestRate);

        if (newInterestRate && newInterestRate < 0.5) {
          newInterestRate = 0.5;
        }

        if (newInterestRate && newInterestRate > 9) {
          newInterestRate = 9;
        }

        formToUpdate.financingInterestRate = newInterestRate;
      } else if (value.id === 'financingViaBsh') {
        formToUpdate.financingInterestRate = calculateFinancingInterestRate(isWpSelected);
      }
    }
    if (name === 'paymentSelection') {
      if (value.id === 'leasingSelfEmployed') {
        formToUpdate = updateByLeasingSelfEmployment(formToUpdate);
      } else if (value.id === 'rentingSelfEmployed') {
        formToUpdate = updateByRentingSelfEmployment(formToUpdate);
      } else if (value.id === 'financing') {
        formToUpdate = {
          ...formToUpdate,
          leasingDownPayment: undefined,
          leasingDownPaymentWp: undefined,
          leasingTerm: undefined,
          leasingTermWp: undefined,
          hirePurchaseDownPayment: undefined,
          hirePurchaseDownPaymentWp: undefined,
          hirePurchaseTerm: undefined,
          hirePurchaseTermWp: undefined,
          financingDownPayment: 0,
          financingTerm: 240,
          financingVia: 'financingViaBsh',
          financingInterestRate: calculateFinancingInterestRate(isWpSelected),
        };
      }
    }

    financingStore.setFinancingForm({ ...formToUpdate, [name]: value.id });
  };

  /**
   * handle numbers change
   * @param name - propery name
   * @param value - new value
   */
  const onNumberInputChangeByName = (name: keyof typeof financingForm) => (value: string | number) => {
    if (!Number.isNaN(Number(value)) || value === '') {
      financingStore.setFinancingForm({ ...financingForm, [name]: value ? Number(value) : undefined });
    }

    if (name === 'leasingDownPayment' && paymentSelection === 'leasingSelfEmployed') {
      const netPrices = getNetPrices(calculatorResult);

      netPrices && financingStore.setFeaturesOnPrice(financingStore.featuresOptions, netPrices, value as number);
    }

    // if (name === 'leasingDownPaymentWp' && paymentSelection === 'leasingSelfEmployed') {
    //   const netPrices = getNetPrices(calculatorResult);

    //   netPrices && financingStore.setFeaturesOnPrice(financingStore.featuresOptions, netPrices, value as number, true);
    // }
  };

  /**
   * calculate discount in precents
   */
  const calculateDiscountInPercent = () =>
    calculator.calculateDiscountInPercent({
      discountInEuros: Number(discountInEuros) as number,
      summaryPriceNetWithSolarSpecial: summaryPriceNetWitSolarSpecialWithoutDiscount,
    });

  /**
   * calculate discount in euro
   */
  const calculateDiscountInEuro = (percent: number) =>
    calculator.calculateDiscountInEuros({
      discountInPercent: percent,
      summaryPriceNetWithSolarSpecial: summaryPriceNetWitSolarSpecialWithoutDiscount,
    });

  /**
   * handle discount blur
   */
  const onDiscountInPercentBlur = (percent?: number) => {
    let adjustedPercent = typeof percent === 'number' ? percent : (discountInPercent as number);

    if (Number(adjustedPercent) <= 0) {
      adjustedPercent = 0;
    } else if (Number(adjustedPercent) >= 100) {
      adjustedPercent = 100;
    }

    const res = calculateDiscountInEuro(adjustedPercent);

    financingStore.setFinancingForm({
      ...financingForm,
      discountInEuros: toFloatNumber(res, 2),
      discountInPercent: adjustedPercent,
    });
  };

  /**
   * handle discount blur
   */
  const onDiscountInEurosBlur = () => {
    if (!Number.isNaN(Number(discountInEuros))) {
      const res = calculateDiscountInPercent();

      if (res >= 100) {
        financingStore.setFinancingForm({
          ...financingForm,
          discountInPercent: 100,
        });

        onDiscountInPercentBlur(100);

        return;
      }

      if (res <= 0) {
        financingStore.setFinancingForm({
          ...financingForm,
          discountInPercent: 0,
        });

        onDiscountInPercentBlur(0);

        return;
      }

      financingStore.setFinancingForm({
        ...financingForm,
        discountInPercent: res,
        discountInEuros,
      });
    }
  };

  /**
   * handle checkboxes change
   */
  const onBshCheckboxChange = (option: CheckboxOption[]) => {
    if (!option[0].checked) {
      financingStore.setFinancingForm({
        ...financingForm,
        bshEstate: option[0].checked ?? false,
        discountInPercent: 0,
        discountInEuros: 0,
        estateDescription: undefined,
      });

      return;
    }
    financingStore.setFinancingForm({
      ...financingForm,
      bshEstate: option[0].checked ?? false,
    });
  };

  /**
   * this is validation function for downPyament fields of leasing section
   */
  const validateLeasingDownPaymentField = (p: string, isWp: boolean = false) => {
    const num = Number(p);

    let priceNet = 0;

    if (!calculatorResult.errors) {
      priceNet = isWp
        ? (calculatorResult as ProductResultType).hotWaterProducts?.summaryPriceNet || 0
        : (calculatorResult as ProductResultType).pvProducts?.summaryPriceNet || 0;
    }

    const maxLeasingValue = priceNet ? calculator.calculateMaxLeasingValue(priceNet) : undefined;

    if (typeof maxLeasingValue !== 'undefined' && num > maxLeasingValue) {
      return {
        validated: false,
        msg: i18n.t('financingPage.downPaymentMaxLeasingError'),
      };
    }

    return { validated: true, msg: '' };
  };

  const { user } = userStore;

  const paymentSelectionValue = paymentSelectionOptions.find((item) => item.serviceId === paymentSelection);
  const financingViaValue = financingViaOptions.find((item) => item.serviceId === financingVia);
  const isFinancingViaBSH = financingViaValue?.serviceId === 'financingViaBsh';

  const isPvSelected = dataStore.formSwitchers.find((feature) => feature.url === pageUrls.photovoltaikQuote && feature.checked)?.checked;

  const selectedFeaturesTitles = featuresOptions
    .filter((item) => item.url !== pageUrls.hotWaterPump)
    .map((feature) => FEATURES_NAMES_MAP_BY_URL[feature.url])
    .join('/');

  const hirePurchaseTermSliderMarks = hirePurchaseTermValues.reduce((acc, val) => ({ ...acc, [val]: <div /> }), {});

  const features = featuresOptions.map((feature) => <CheckboxComponent key={feature.id} options={[feature]} onChange={onFeaturesChange} />);

  const isLeasingDownPaymentVisible = () => {
    const isPv = featuresOptions.find((feature) => feature.url === pageUrls.photovoltaikQuote)?.checked;

    if (isPvSelected) {
      if (isPv) {
        return true;
      }

      const isAnyButWpSelected =
        featuresOptions.filter(
          (feature) =>
            feature.url !== pageUrls.hotWaterPump &&
            feature.url !== pageUrls.basicData &&
            feature.url !== pageUrls.financing &&
            feature.checked,
        ).length > 0;

      return isAnyButWpSelected;
    }

    const isAnySelected = featuresOptions.filter((feature) => feature.checked).length > 0;

    return isAnySelected;
  };

  return (
    <ContentArea>
      <div className="financing-content-block">
        <div className="financing-row-main">
          <h2 className="financing-subtitle">{i18n.t('forms.financing')}</h2>
        </div>
        <div className="financing-row-secondary">
          <Select
            title={i18n.t('financingPage.selection')}
            options={getPaymentOptions()}
            onSuggestionSelected={onSelectChangeByName('paymentSelection')}
            value={paymentSelectionValue}
            testId="paymentSelection"
          />
        </div>
        {paymentSelection !== 'factoring' && (
          <>
            <p className="financing-checkbox-subtitle">{i18n.t('financingPage.subtitle')}</p>
            <div className="financing-row-features">{features}</div>
          </>
        )}
        {paymentSelection === 'leasingSelfEmployed' && (
          <div data-testid="leasing-block">
            {isLeasingDownPaymentVisible() && (
              <div>
                <p className="financing-rows-title">{`${i18n.t('financingPage.leasingSelection')} ${selectedFeaturesTitles}`}</p>
                <div className="financing-row-secondary">
                  <Tooltip text={i18n.t('financingPage.leasingTooltip')} isShow={showLeasingTooltip} position="right">
                    <InputText
                      type="number"
                      title={i18n.t('financingPage.downPayment')}
                      onChange={onNumberInputChangeByName('leasingDownPayment')}
                      value={financingForm.leasingDownPayment}
                      onMouseEnter={() => setShowLeasingTooltip(true)}
                      onMouseLeave={() => setShowLeasingTooltip(false)}
                      suffix="€"
                      validateFunc={(v) => validateLeasingDownPaymentField(v)}
                      testId="leasing-down-payment"
                    />
                  </Tooltip>
                  <Slider
                    min={96}
                    max={120}
                    step={12}
                    inputTestId="leasing-term"
                    defaultValue={96}
                    value={Number(financingForm.leasingTerm)}
                    label={i18n.t('financingPage.term')}
                    onChange={onNumberInputChangeByName('leasingTerm')}
                    className="price-slider"
                  />
                </div>
              </div>
            )}
            {isWpSelected && (
              <div>
                <p className="financing-rows-title">{i18n.t('financingPage.leasingSelectionWp')}</p>
                <div className="financing-row-secondary">
                  <Tooltip text={i18n.t('financingPage.leasingTooltip')} isShow={showLeasingTooltipWp} position="right">
                    <InputText
                      type="number"
                      title={i18n.t('financingPage.downPayment')}
                      onChange={onNumberInputChangeByName('leasingDownPaymentWp')}
                      onMouseEnter={() => setShowLeasingTooltipWp(true)}
                      onMouseLeave={() => setShowLeasingTooltipWp(false)}
                      value={financingForm.leasingDownPaymentWp}
                      suffix="€"
                      validateFunc={(v) => validateLeasingDownPaymentField(v, true)}
                      testId="leasing-down-payment"
                    />
                  </Tooltip>
                  <Slider
                    min={96}
                    max={108}
                    step={12}
                    label={i18n.t('financingPage.term')}
                    inputTestId="leasing-term"
                    defaultValue={96}
                    value={Number(financingForm.leasingTermWp)}
                    onChange={onNumberInputChangeByName('leasingTermWp')}
                    className="price-slider"
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {paymentSelection === 'rentingSelfEmployed' && (
          <div data-testid="renting-block">
            <div>
              <p className="financing-rows-title">{i18n.t('financingPage.rentingSelection')}</p>
              <div className="financing-row-secondary">
                <InputText
                  type="number"
                  title={i18n.t('financingPage.downPayment')}
                  suffix="€"
                  onChange={onNumberInputChangeByName('hirePurchaseDownPayment')}
                  value={financingForm.hirePurchaseDownPayment as number}
                  testId="renting-down-payment"
                />
                <Slider
                  min={36}
                  max={120}
                  marks={hirePurchaseTermSliderMarks}
                  inputTestId="renting-term"
                  label={i18n.t('financingPage.term')}
                  testId="inflation-slider"
                  value={Number(financingForm.hirePurchaseTerm)}
                  onChange={onNumberInputChangeByName('hirePurchaseTerm')}
                  className="price-slider"
                />
              </div>
            </div>
            {/* TODO: Deniz said to remove this section */}
            {/* {isWpSelected && (
              <div>
                <p className="financing-rows-title">{i18n.t('financingPage.rentingSelectionWp')}</p>
                <div className="financing-row-secondary">
                  <InputText
                    type="number"
                    title={i18n.t('financingPage.downPayment')}
                    onChange={onNumberInputChangeByName('hirePurchaseDownPaymentWp')}
                    value={financingForm.hirePurchaseDownPaymentWp as number}
                    validateFunc={downPaymentValidation}
                    testId="renting-down-payment"
                  />
                  <Slider
                    min={36}
                    max={120}
                    defaultValue={36}
                    step={12}
                    inputTestId="renting-term"
                    testId="inflation-slider"
                    value={Number(financingForm.hirePurchaseTermWp)}
                    label={i18n.t('financingPage.term')}
                    onChange={onNumberInputChangeByName('hirePurchaseTermWp')}
                    className="price-slider"
                  />
                </div>
              </div>
            )} */}
          </div>
        )}
        {paymentSelection === 'financing' && (
          <>
            <div className="financing-row-secondary" data-testid="financing-block">
              <Select
                title={i18n.t('financingPage.via')}
                options={getFinancingViaOptions()}
                onSuggestionSelected={onSelectChangeByName('financingVia')}
                value={
                  financingViaValue
                    ? {
                      id: financingViaValue?.serviceId,
                      label: financingViaValue?.name,
                    }
                    : null
                }
                testId="financingVia"
              />
              <InputText
                type="number"
                title={i18n.t('financingPage.downPayment')}
                onChange={onNumberInputChangeByName('financingDownPayment')}
                value={financingDownPayment}
                suffix="€"
                testId="financingDownPayment"
              />
              <Tooltip text={i18n.t('financingPage.financingViaBshTooltip')} isShow={showFinancingViaBshTooltip} position="right">
                <InputText
                  type="number"
                  onMouseEnter={() => isFinancingViaBSH && setShowFinancingViaBshTooltip(true)}
                  onMouseLeave={() => isFinancingViaBSH && setShowFinancingViaBshTooltip(false)}
                  title={i18n.t('financingPage.interestRate')}
                  value={financingInterestRate}
                  isDisabled={isFinancingViaBSH}
                  testId="interestRate"
                  suffix="%"
                  min={financingVia === 'financingViaHouseBank' ? MIN_INTEREST_RATE_HOUSE_BANK : undefined}
                  max={financingVia === 'financingViaHouseBank' ? MAX_INTEREST_RATE_HOUSE_BANK : undefined}
                  hint={financingVia === 'financingViaHouseBank' ? 'Mindestens 0,5, aber maximal 9 %' : undefined}
                  onChange={onNumberInputChangeByName('financingInterestRate')}
                />
              </Tooltip>
            </div>
            <div className="financing-term-row">
              <Slider
                min={24}
                max={240}
                step={1}
                inputTestId="financingTerm"
                value={Number(financingForm.financingTerm)}
                label={i18n.t('financingPage.term')}
                onChange={onNumberInputChangeByName('financingTerm')}
                className="financing-term-slider"
              />
            </div>
          </>
        )}
      </div>
      {isPvSelected && (
        <Dropdown
          summaryTitle={i18n.t('forms.offerAdjustment')}
          wrapperClass="financing-dropdown"
          customArrow={arrow}
          contentTestId="financing-dropdown-content"
          summaryTestId="financing-dropdown-summary"
        >
          <>
            <div className="financing-row-main">
              <CheckboxWithInput
                label={i18n.t('Solar Spezial')}
                showInput
                spacing={20}
                inputSuffix="€"
                testId="solar-special"
                checked={financingForm.solarSpecial}
                onChange={() => {
                  financingStore.setFinancingForm({
                    ...financingForm,
                    solarSpecial: !financingForm.solarSpecial,
                    discountInEurosSolarSpecial: financingForm.solarSpecial ? 0 : financingForm.discountInEurosSolarSpecial,
                  });
                }}
                inputValues={[{ label: i18n.t('Nachlass'), value: discountInEurosSolarSpecial }]}
                setValues={(values) => {
                  financingStore.setFinancingForm({
                    ...financingForm,
                    discountInEurosSolarSpecial: Number(values[0].value),
                  });
                }}
                onBlur={onDiscountInEurosBlur}
              />
            </div>
            <div className="financing-row-main">
              <CheckboxComponent
                options={[
                  {
                    id: 'bshEstate',
                    checked: bshEstate ?? false,
                    value: i18n.t('BSH NL'),
                    testId: 'bshEstate',
                  },
                ]}
                onChange={onBshCheckboxChange}
                isSingle
              />
            </div>
            {bshEstate && (
              <>
                <div className="financing-row-main" style={{ justifyContent: 'left', columnGap: 10, alignItems: 'flex-end' }}>
                  <InputText
                    type="number"
                    title={i18n.t('financingPage.discount')}
                    className="financing-main-input"
                    value={discountInEuros ? toFloatNumber(discountInEuros, 2) : ''}
                    suffix="€"
                    onChange={onNumberInputChangeByName('discountInEuros')}
                    onBlur={onDiscountInEurosBlur}
                  />
                  <InputText
                    title=""
                    type="number"
                    className="financing-main-input"
                    onChange={onNumberInputChangeByName('discountInPercent')}
                    suffix="%"
                    value={discountInPercent ? toFloatNumber(discountInPercent, 2) : ''}
                    onBlur={onDiscountInPercentBlur}
                  />
                </div>
                <div className="financing-row-main">
                  <InputText
                    type="text"
                    title={i18n.t('financingPage.estateDescription')}
                    onChange={(value) =>
                      financingStore.setFinancingForm({
                        ...financingForm,
                        estateDescription: value as string,
                      })}
                    value={estateDescription}
                  />
                </div>
              </>
            )}
            {user.getPermissions().shouldShowOfferUnitPrices && (
              <div className="financing-row-main">
                <CheckboxComponent
                  options={[
                    {
                      id: 'offerUnitPrices',
                      checked: financingForm.offerUnitPrices ?? false,
                      value: i18n.t('financingPage.showIndPrices'),
                      testId: 'offerUnitPrices',
                    },
                  ]}
                  onChange={(option: CheckboxOption[]) => {
                    financingStore.setFinancingForm({
                      ...financingForm,
                      offerUnitPrices: option[0].checked ?? false,
                    });
                  }}
                  isSingle
                />
              </div>
            )}
          </>
        </Dropdown>
      )}
      <Bottom>
        <Button role="btnCancel" text={i18n.t('buttons.back')} onClick={goToPreviousPage} />
        <Button role="btnNext" text={i18n.t('buttons.next')} testId="next-button" isGreen leftMargin20 isDisabled />
      </Bottom>
    </ContentArea>
  );
});

export default Financing;
