/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is API requests lists from broker crm server
 */

import CustomAPI from '../CustomAPI';
import { APIResponse } from '../../types/TGeneral';
import { IGetDealDataResponse } from '../../interfaces/IN8N';

const Deal = {
  /*
   * get deal data from the server
   * @param dealId
   */
  getData: async (dealId: string): Promise<APIResponse<IGetDealDataResponse>> => {
    const res = (await CustomAPI.request('get', `/workflow/crm-deal/${dealId}`)) as APIResponse<IGetDealDataResponse>;

    return res;
  },

  /**
   * request to synchronize address in crm
   * @param calculationId - string - object id in Calculation table
   */
  async syncCrmAddress(calculationObjectId: string) {
    await CustomAPI.request('post', `/workflow/upsert-object-address/${calculationObjectId}`);
  },
};

export default Deal;
